$c1: #3c6464;
$c2: rgba(47, 79, 79, 0.5);
$c3: black;
$c4: white;
$c5: transparent;
$c6: #212529;
$c7: whitesmoke;
$c8: #2a3a46;
$c9: #dee2e6;
$c10: #3c5364;
$c11: rgba($c1, 0.85);
$c12: red;
$c13: #e2e3e5;
$c14: #eeeeee;
$c15: rgba(47, 79, 79, 0.1);
$c16: darken(#FBD725, 12%);
$c17: #c4c8cb;
$c18: #e2e3e5;
$c19: #757575;
