.cards {
    .card {
        cursor: pointer;
        width: 83.33%;
        @include min_sm {
            width: 48%;
        }
        @include min_lg {
            width: 31.5%;
        }
        &:hover {
            img {
                transform: scale(1.04);
            }
            svg {
                transform: translateX(50%);
            }
        }
        .card-inner {
            background-color: $c1;
        }
    }
    a {
        text-decoration: none;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.01);
        transition: transform 0.3s;
        will-change: transform;
    }
    h3 {
        font-weight: 600;
        font-size: 20px;
        color: $c4;
    }
    b {
        color: $c4;
        font-size: 18px;
        font-weight: 500;
    }
    p {
        color: $c4;
        font-size: 16px;
        font-weight: 400;
    }
    .div-button {
        background-color: $c4;
        border: none;
        color: $c8;
    }
    svg {
        transition: transform 0.3s;
        will-change: transform;
        transform: translateX(0);
    }
    h2 {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-color: $c1;
            background-repeat: repeat-x;
            width: 40px;
            height: 1px;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-color: $c1;
            background-repeat: repeat-y;
            width: 1px;
            height: 40px;
        }
    }
}
