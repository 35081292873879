.Auth-form-container .accordion-button:not(.collapsed) {
    background-color: $c2;
    color: $c6;
}

.Auth-form-container .accordion-button:focus {
    box-shadow: none;
}

.Auth-form-container .accordion-button {
    background-color: $c7;
}

.Auth-form-container .accordion-button::after {
    background-image: url("../../images/accordian-dark.svg");
}

.Auth-form-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: calc(100vh - 246px);
    @include min_md {
        min-height: calc(100vh - 212px);
    }
    .accordion {
        max-width: 400px;
        width: 100%;
        input {
            border: 1px solid $c13;
            box-shadow: none;
            color: $c6;
            transition: border 0.3s ease-in-out;
            will-change: border;
            &::placeholder {
                color: $c19;
            }
            &:hover,
            &:focus {
                outline: none;
                border: 1px solid $c2;
            }
        }
        .btnn {
            color: $c6;
            border: 1px solid $c13;
            background-color: $c14;
            transition:
                background-color 0.3s ease-in-out,
                border 0.3s ease-in-out;
            will-change: background-color, border;
            font-weight: 400;
            &:hover {
                background-color: $c18;
                border: 1px solid $c5;
            }
        }
        label {
            display: block;
            color: $c6;
        }
        .display {
            display: block;
            margin-top: 16px;
            width: 100%;
        }
        .displayNone {
            display: none;
        }
        #responseCaptcha {
            background-color: unset;
            color: $c6;
        }
        .spinner {
            height: 30px;
            width: fit-content;
            display: block;
            margin: auto;
            border: none;
            background-color: $c13;
            border-radius: 50%;
            padding: 0.2rem;
        }
    }
    .rady {
        border-radius: 6px;
    }
}
