.slider_9-container {
    background-color: $c10;
}

.slider_9-small-col {
    background-color: $c1;
}
 
.slider_9-inner {
    background-color: $c9;
    height: 100%;
    position: relative;
    img {
        width: 0px;
        height: 0px;
        aspect-ratio: 3 / 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;
        transition: opacity 0.1s;
        will-change: opacity;
        opacity: 0.88;
        position: absolute;
        &.has-current {
            width: 100%;
            height: 100%;
            opacity: 1;
            position: static;
        }
        &.has-fade {
            width: 100%;
            height: 100%;
            opacity: 0.88;
            position: static;
        }
    }
    button {
        width: fit-content;
    }
}

.slider_9-large-col {
    p {
        background-color: $c5;
        font-size: clamp(18px, 2vw, 20px);
    }
}

.slider_9-button-container {
    background-color: $c1;
    border-top: 1px solid $c9;
    border-bottom: 1px solid $c9;
    @include min_sm {
        border: none;
    }
}

.slider_9-button {
    outline: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $c9;
    transition: transform 0.5s;
    transform: scale(1);
    will-change: transform;
    svg {
        fill: $c4;
        width: 20px;
        margin: 0;
        transition: opacity 0.2s ease;
        will-change: opacity;
        opacity: 1;
    }
    &:hover {
        transform: scale(0.94);
        svg {
            opacity: 0.5;
        }
    }
}
