.slider_1-outer {
    @include min_md {
        margin: 0 52px;
    }
    @include min_xl {
        margin: 0 39px;
    }
}
.slider_1-row {
    overflow: hidden;
    padding: 0 2px;
}

.slider_1-item {
    flex-shrink: 0;
    width: 100%;
    @include min_md {
        padding: 0 26px 0 0;
    }
}

.slider_1-item,
.slider_1-button-container {
    will-change: transform;
    @include min_md {
        width: calc(50% + 13px);
    }
    @include min_xl {
        width: calc(33.333% + 8.666px);
    }
}

.slider_1-item-padding {
    margin: auto;
    width: calc(100% - 104px);
    @include min_sm {
        width: 70%;
    }
    @include min_md {
        width: 100%;
    }
}

.slider_1-item-body {
    border: 1px solid $c1;
    background-color: $c9;
    width: 100%;
    padding: 33px 33px 50px 33px;
    h3 {
        font-size: clamp(22px, 2.75vw, 28px);
        color: $c6;
    }
    b {
        font-weight: 500;
        color: $c6;
    }
    p,
    b {
        font-size: 17.5px;
        color: $c6;
    }
    p {
        background-color: $c5;
    }
}

.slider_1-item-body:after {
    position: absolute;
    content: " ";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 17px;
    background-color: $c1;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
}

.slider_1-button {
    transform: translateX(26px);
    margin: 0;
    width: 52px;
    height: 52px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    outline: none;
    border: none;
    background-color: transparent;
    svg {
        position: relative;
        z-index: 1;
        color: $white;
        transition:
            transform 0.4s ease-in-out,
            opacity 0.4s ease-in-out;
        will-change: transform, opacity;
        opacity: 1;
        transform: scale(1);
    }
    &::after {
        content: " ";
        display: flex;
        width: 100%;
        height: 100%;
        background-color: $c1;
        border-radius: 50%;
        transition: transform 0.4s ease-in-out;
        will-change: transform;
        transform: scale(1);
        position: absolute;
        justify-content: center;
        color: $white;
    }
    &:hover {
        &::after {
            transform: scale(0.95);
        }
        svg {
            transform: scale(0.95);
            opacity: 0.75;
        }
    }
}

.slider_1-button-container {
    pointer-events: none;
}

.slider-next-md,
.slider-next-lg {
    pointer-events: auto;
}

.slider_1-button-container,
.slider-next-sm {
    top: calc(50% - 52px);
    right: 0;
}

.slider-next-md.slider_1-button {
    display: none;
    @include min_md {
        display: flex;
    }
    @include min_xl {
        display: none;
    }
}

.slider-next-lg.slider_1-button {
    display: none;
    @include min_xl {
        display: flex;
    }
}

.slider-next-sm.slider_1-button {
    display: flex;
    @include min_md {
        display: none;
    }
}
