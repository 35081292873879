.paypal {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $c4;
    font-size: 17px;
    @include min_sm {
        font-size: 20px;
    }
    .bg {
        background-color: $c1;
    }
    h3 {
        color: $c6;
    }
    .order-image {
        clip-path: circle(39%);
        background-color: $c4;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            clip-path: circle(35%);
            opacity: 1;
            transition: opacity 0.1s;
            will-change: opacity;
            &.fade {
                opacity: 0.8;
            }
        }
    }
    .counter {
        color: $c4;
        font-size: 28px;
        span {
            width: 40px;
            height: 40px;
            opacity: 1;
            transition: opacity 0.3s;
            will-change: opacity;
            &:hover {
                opacity: 0.75;
            }
        }
        input {
            color: $c4;
            border: none;
            background: none;
            -webkit-text-fill-color: $c4;
            opacity: 1;
            width: 40px;
            height: 40px;
            font-size: 24px;
        }
        input:disabled {
            color: $c4;
        }
    }
    .spinner,
    .button-container-inner {
        height: 25px;
        background-color: $c14;
        border-radius: 13px;
        @include min_xxs {
            height: 35px;
            border-radius: 18px;
        }
        @include min_xs {
            height: 45px;
            border-radius: 23px;
        }
        @include min_md_pp {
            height: 55px;
            border-radius: 28px;
        }
        @include min_xl {
            height: 45px;
            border-radius: 23px;
        }
    }
    .spinner {
        object-fit: scale-down;
        display: block;
    }

    .button-container-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $c6;
        font-weight: 500;
    }

    button {
        border: none;
        font-weight: 500;
        opacity: 1;
        transition: opacity 0.3s;
        will-change: opacity;
        &:hover {
            opacity: 0.75;
        }
    }
    .reSelect {
        opacity: 1;
        cursor: pointer;
        transition: opacity 0.3s;
        will-change: opacity;
        &:hover {
            opacity: 0.75;
        }
    }
    .remove {
        background-color: $c5;
        color: $c4;
        font-size: 15px;
        opacity: 1;
        transition: opacity 0.3s;
        will-change: opacity;
        &:hover {
            opacity: 0.75;
        }
    }
    button.button-container-inner {
        opacity: 1;
        transition: opacity 0.3s;
        will-change: opacity;
        &:hover {
            opacity: 0.9;
        }
    }
    .total {
        font-size: 15px;
    }
    label[for="select"] {
        background-color: $c4;
        color: $c6;
        font-size: 17px;
    }

    table {
        width: 100%;
        font-size: 70%;
        color: $c6;
        th {
            position: absolute;
            clip: rect(0 0 0 0);
        }
        tr {
            display: block;
            border-top: 1px solid $c4;
            background-color: $c4;
        }
        thead tr,
        tr:nth-child(1) {
            border: none;
        }
        td {
            display: block;
            text-align: right;
            padding: 20px;
            word-break: break-word;
            &::before {
                content: attr(headers);
                text-align: left;
                display: block;
                font-weight: 600;
                text-transform: capitalize;
                height: 100%;
                margin: 0 0 1rem 0;
            }
            &:nth-child(odd) {
                background-color: $c2;
            }
        }
    }
}
