.slider_8-navigation {
    background-color: $c1;
    z-index: 999;
    overflow: hidden;
    will-change: top, max-height;
    max-height: 83px;
    border-bottom: 1px solid $c9;
    @include min_md {
        width: 300px;
        position: absolute;
        right: 0;
        border: none;
        z-index: 1001;
    }
    .navbar-collapse {
        background-color: inherit;
        overflow: hidden;
        border-top: 1px solid $c9;
        @include min_md {
            border: none;
        }
    }
    a {
        color: $c9;
        letter-spacing: 0.0125em;
        text-decoration: underline;
        text-decoration-color: $c5;
        transition: text-decoration-color 0.2s;
        will-change: text-decoration-color;
        &:hover {
            text-decoration-color: $c9;
        }
    }
    li {
        will-change: transform;
    }
    a.active {
        text-decoration-color: $c9;
        text-underline-offset: 5px;
    }
    button.navbar-toggler {
        outline: none;
        border: none;
        box-shadow: none;
        background-color: initial;
    }
    svg {
        fill: $c4;
        font-style: oblique;
    }
}

.slider_8-navbar-toggler {
    .slider_8-bar {
        width: 24px;
        height: 3px;
        background-color: $c9;
        margin: 4px 0;
        border-radius: 1.5px;
        will-change: transform;
    }
}
