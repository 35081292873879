.two-row-feature {
    background-color: $c1;
    h2 {
        line-height: 2rem;
        color: $c9;
        font-size: clamp(18px, 3vw, 30px);
        @include min_md {
            line-height: 3rem;
        }
        @include min_xxl {
            line-height: 4.5rem;
        }
        .lower {
            color: $c4;
        }
    }
    p {
        color: $c9;
        font-size: clamp(18px, 2vw, 20px);
        line-height: 1.6;
    }
    img {
        object-fit: cover;
        width: 100%;
        height: min-content;
        aspect-ratio: 1 / 1;
        border: 6px solid $c9;
        border-radius: 100%;
        margin-top: 2rem;
        @include min-xxl {
            transform: translateY(-50%);
        }
    }
    .points {
        background-color: $c2;
        border-radius: 5rem;
        color: $c4;
        .tag {
            color: $c9;
        }
    }
    h3 {
        background-color: $c2;
        border-radius: 5rem;
        color: $c4;
        font-size: 20px;
    }
    ul {
        color: $c9;
        font-size: 16px;
    }
    svg {
        fill: $c4;
        border-radius: 50%;
        border: 1px solid $c4;
    }
}
